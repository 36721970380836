/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import React, {ComponentType, ReactNode, lazy} from 'react';
import SafeUpdate from 'packages/helpers/SafeUpdate';
import {matchPath, RedirectProps} from 'react-router-dom';
import * as runtypes from 'runtypes';
import Page404React from 'src/jsx/errors/404page.react';

const Landing = lazy(() => import('src/jsx/landing/Landing.react'));
const FAQ = lazy(() => import('src/jsx/faq/faq.react'));
const Rent = lazy(() => import('src/jsx/rent/rent.react'));
const Fleet = lazy(() => import('src/jsx/fleet/fleet.react'));
const OneDayTours = lazy(() => import('src/jsx/tours/one.react'));
const MultiDayTours = lazy(() => import('src/jsx/tours/multi.react'));
const Contacts = lazy(() => import('src/jsx/contacts/contacts.react'));
const Console = lazy(() => import('src/jsx/console/Console'));
const ConsoleLanding = lazy(() => import('src/jsx/console/ConsoleLanding'));
const ConsoleFAQPage = lazy(() => import('packages/faq/ConsoleFAQPage'));
const ConsoleToursPage = lazy(() => import('packages/tours/ConsoleToursPage'));
const ConsoleContactsPage = lazy(() => import('packages/contacts/ConsoleContactsPage'));
const ConsoleFleetPage = lazy(() => import('packages/fleet/ConsoleFleetPage'));
const Franchising = lazy(() => import('src/jsx/franchising/franchising.react'));
const MyReservations = lazy(() => import('src/jsx/reservations/MyReservationsPage'));

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id: string;
    childRoutes: Page[];
    children?: ReactNode;
    meta: {
        is_loading: boolean;
        is_error: boolean;
        payload: T;
        redirect_source?: string;
    };
};

export const pages: Page<any>[] = [];
export const redirects: RedirectProps[] = [];

export const Page404: Page = {
    id: '404error',
    path: '',
    exact: true,
    Component: Page404React,
    meta: EmptyMeta,
};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    franchising: {path: ':lang/franchising', exact: true, Component: Franchising, meta: EmptyMeta},
    faq: {path: ':lang/faq', exact: true, Component: FAQ, meta: EmptyMeta},
    rent: {path: ':lang/rent/:uri?', exact: true, Component: Rent, meta: EmptyMeta},
    fleet: {path: ':lang/fleet', exact: true, Component: Fleet, meta: EmptyMeta},
    contacts: {path: ':lang/contacts', exact: true, Component: Contacts, meta: EmptyMeta},
    oneDayTours: {path: ':lang/one-day-tours/:id?', exact: true, Component: OneDayTours, meta: EmptyMeta},
    reservations: {path: ':lang/my-reservations', exact: true, Component: MyReservations, meta: EmptyMeta},
    multiDayTours: {path: ':lang/multi-day-tours', exact: true, Component: MultiDayTours, meta: EmptyMeta},
    console: {
        path: ':lang/console',
        exact: false,
        Component: Console,
        meta: EmptyMeta,
        subRoutes: {
            consoleLanding: {
                path: '',
                exact: true,
                Component: ConsoleLanding,
                meta: EmptyMeta,
                name: 'Pages Console',
            },
            consoleFAQ: {
                path: 'faq',
                exact: true,
                Component: ConsoleFAQPage,
                meta: EmptyMeta,
                name: 'FAQ Console',
            },
            consoleTours: {
                path: 'tours',
                exact: true,
                Component: ConsoleToursPage,
                meta: EmptyMeta,
                name: 'Tour Console',
            },
            consoleContacts: {
                path: 'contacts',
                exact: true,
                Component: ConsoleContactsPage,
                meta: EmptyMeta,
                name: 'Contacts Console',
            },
            consoleFleet: {
                path: 'fleet',
                exact: true,
                Component: ConsoleFleetPage,
                meta: EmptyMeta,
                name: 'Fleet Console',
            },
        },
    },
    landing: {path: 'en', exact: true, Component: Landing, meta: EmptyMeta},
    landingDe: {path: 'de', exact: true, Component: Landing, meta: EmptyMeta},
    landingIt: {path: 'it', exact: true, Component: Landing, meta: EmptyMeta},
    landingNl: {path: 'nl', exact: true, Component: Landing, meta: EmptyMeta},
    landingRu: {path: 'ru', exact: true, Component: Landing, meta: EmptyMeta},
};

type PageEntry = Omit<Page, 'id' | 'subRoutes'> & {
    subRoutes?: Record<string, PageEntry>;
};

function MakePage(key: PageID, obj: PageEntry): Page {
    let page: Page = {...obj, id: key, subRoutes: []};
    if (obj.subRoutes) {
        page.subRoutes = Object.entries(obj.subRoutes).map(entry => MakePage(entry[0] as PageID, entry[1]));
    }

    return page;
}

const Routes: Page[] = Object.entries(RoutesObject).map(entry => MakePage(entry[0] as PageID, entry[1]));
AddRoutes(Routes);

export type PageID = '404error' | keyof typeof RoutesObject;

export interface Page<T = null> {
    id: PageID;
    parent_id?: string;
    path: string | string[];
    exact: boolean;
    Component: ComponentType<PageProps<T>> | React.LazyExoticComponent<React.FC>;
    meta: runtypes.Runtype;
    subRoutes?: Page<T>[];
    name?: string;
}

export function AddRoutes<T = null>(routes: Page<T>[], parent?: Page<T>) {
    routes.forEach(route => {
        if (parent) {
            route = SafeUpdate(route, {parent_id: {$set: parent.id}});
            route = SafeUpdate(route, {path: {$set: `${parent.path}/${route.path}`}});
        }

        pages.push(route);
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route);
        }
    });
}

export function FindPage(Pages: Page[], path: string) {
    return Pages.find(page => {
        return matchPath(path, {
            path: typeof page.path === 'string' ? `/${page.path}` : page.path.map(p => `/${p}`),
            exact: page.exact,
        });
    });
}
